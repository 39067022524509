<template>
  <div>
    <CRow>
      <CCol sm="12">
        <h3>Agregar nuevo modelo</h3>
        <br>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="4">
        <CCard>
          <CForm v-bind:wasValidated="this.validationError">
            <CCardBody>
                <CToaster v-if="errores.length" :autohide="3000">
                  <template v-for="error in errores">
                    <CToast
                      color="danger"
                      :key="'error' + error"
                      :show="true"
                      header="Error de validación"
                    >
                      {{error}}
                    </CToast>
                  </template>
                </CToaster>
              <CInput v-model="nombre"
                type="text"
                description="Recorda respetar errores de ortografía ya que este nombre también se mostrara a los clientes."
                autocomplete="modelo"
                label="Nombre del modelo"
                placeholder="Ingresar nombre..."
                required
              />
              <CSelect ref="marca"
                  :value.sync="marca"
                  label="Marca del modelo"
                  :options="generateSelect()"
                  placeholder="Selecciona una marca"
                  autocomplete="marca"
                  description="Si la marca de tu módelo no se muestra acá, consulta con el administrador de la plataforma."
                  required
                />
            </CCardBody>
            <CCardFooter>
              <CButton v-on:click.prevent="triggerCreateModelo()" type="submit" color="success">Crear modelo</CButton>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
      <CCol v-if="modelos.length > 0" sm="8">
        <CCard>
          <CCardBody>
            <p>Listado de modelos creados</p>
            <CDataTable :alignVertical="true"
              :items="modelos"
              :fields="fields"
              :items-per-page="200"
              :columnFilter="false"
              :tableFilter="false"
              :sorterValue="{ asc: true }"
              :sorter='{ external: false, resetable: true }'
              pagination
            >
            <template #marca_id="{item}">
              <td>
                <span>{{parseMarca(item.marca_id)}}</span>
              </td>
            </template>
            <template #createdAt="{item}">
              <td>
                <span>{{new Date(item.createdAt).getDate() + '/' +new Date(item.createdAt).getMonth()+ '/'+new Date(item.createdAt).getFullYear()+ ' ' +  new Date(item.createdAt).getHours() + ':' + new Date(item.createdAt).getMinutes()}}</span>
              </td>
            </template>
            <template #acciones="{item}">
              <td>
                <CButtonGroup >
                  <CButton v-on:click="triggerUpdateModelo(item)" v-c-tooltip="'Editar'" color="info"><CIcon name="cil-pencil" /></CButton>
                  <CButton v-on:click="triggerDeleteModelo(item)" v-c-tooltip="'Eliminar'" color="danger"><CIcon name="cil-x-circle" /></CButton>
                </CButtonGroup>
              </td>
            </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const modelosService = require('../../services/modelos.service');
const Swal = require('sweetalert2');
const marcasService = require('../../services/marcas.service');

export default {
  name: 'nuevoModelo',
  data() {
    return {
      fields: this.generateFields(),
      modelos: [],
      marcas: [{}],
      errores: [],
      validationError: false,
      nombre: null,
      marca: null,
      isLoading: true,
    }
  },
  created() {
    this.getMarcas();
  },
  updated() {
  },
  methods: {
    getMarcas: function() {
      marcasService.getMarcas().then((response) => {
        // handle success
        this.marcas = response.data.marcas;
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
    },
    generateSelect(){
      let select = [];
      this.marcas.forEach(marca => {
          select.push({value: marca.id, label: marca.name})
      });
      return select;
    },
    generateFields(){
      const fields = [{
        key: 'name' ,
        label: 'Modelo',
      },{
        key: 'marca_id' ,
        label: 'Marca',
      },{
        key: 'createdAt' ,
        label: 'Fecha',
      },{
        key: 'acciones' ,
        label: 'Acciones',
        _style: 'width: 100px;',
        filter: false,
        sorter: false
      }];

      return fields;
    },
    parseMarca(marca_id) {
      let marca_name = '';
      this.marcas.forEach(marca => {
        if(marca.id == marca_id){
          marca_name = marca.name
        }
      });
      return marca_name;
    },
    checkForm: function () {
      if (this.nombre && this.marca) {
        return true;
      }

      this.errores = [];
      this.validationError = true;
      if (!this.nombre) {
        this.errores.push('El nombre del modelo es obligatorio.');
      }
      if (!this.marca) {
        this.errores.push('La marca del modelo es obligatoria.');
      }
    },
    async triggerCreateModelo(){
      if(!this.checkForm()){
        return;
      }
      const modelo = {
        name: this.nombre,
        marca_id: this.marca,
        usuario_id: this.$store.state.user.data.id
      }
      modelosService.createModelo(modelo).then((result) => {
        Swal.fire('¡Operación realizada!', 'El modelo fue creado', 'success');
        this.modelos.push(result.data);
      }).catch((error) => {
        // handle error
        console.log(error);
        Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
      });
    },
    async triggerDeleteModelo(modelo){
      await Swal.fire({
        title: 'Eliminar modelo',
        html: `Vas a eliminar el modelo: <br>${modelo.name} <br><br><strong>¿Estás seguro?</strong>`,
        showDenyButton: true,
        confirmButtonText: `Confirmar`,
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          modelosService.deleteModelo(modelo.id).then(() => {
            Swal.fire('¡Operación realizada!', 'El modelo fue eliminado', 'success');
            this.modelos = this.modelos.filter(function(item) {
                return item.id != modelo.id;
            });
          }).catch((error) => {
            // handle error
            console.log(error);
            Swal.fire('Hubo un error', 'Consulte al administrador', 'error')
          });
        } else if (result.isDenied) {
          Swal.fire('Operación cancelada', '', 'error')
        }
      });
    }
  }
}
</script>
